<template>
    <Dialog
            :visible="isOpen"
            class="p-fluid"
            :style="{ width: '80%', height: 'auto' }"
            :draggable="false"
            :closable="false"
            :modal="true"
        >
            <template #header>
                <h3> <i v-tooltip="'Registro de medição'" class="pi pi-info-circle"></i> {{ title }} </h3>
            </template>
            <div class="grid">
                <div class="col-12">
                    <Panel>
                        <template #header>
                            <div
                                class="justify-content-center"
                                style="text-align: center; width: 100%"
                            >
                                <h3>Fonte Geradora: {{ record?.fonteGeradora?.nome }} </h3>
                                <h4>Descrição de risco: {{ record?.aplicacaoPerigoDescricaoRisco?.riscoDescricaoRisco?.descricaoRisco?.nome }} </h4>
                            </div>
                        </template>

                        <FonteGeradoraMedicaoGrid
                            title="Medição"
                            :service="serviceFonteGeradora"
                            :parentName="'fonte-geradora'"
                            :queryParams="{
                                aplicPerigoFonteGeradoraId: record.id
                            }"
                            :risco="risco"
                            :labelBotaoAdicionarItem="'Inserir medição'"
                        />
                    </Panel>
                </div>

                <div class="flex flex-column md:flex-row md:justify-content-end md:ml-auto">
                    <Button
                        @click="close"
                        label="Fechar"
                        class="mr-3"
                    />
                </div>
            </div>
    </Dialog>
</template>

<script>
import NestedService from '../../../../../services/NestedService';
import FonteGeradoraMedicaoGrid from './FonteGeradoraMedicaoGrid.vue'

export default {
    components: {
        FonteGeradoraMedicaoGrid
    },
    props: {
        title: String,
        risco: Object
    },
    created() {
        this.serviceFonteGeradora = new NestedService('/aplicacao-perigo-fonte-geradora-medicao');
    },
    data() {
        return {
            isOpen: false,
            record: null
        }
    },
    methods: {
        open(slotProps) {
            this.isOpen = true;
            this.record = slotProps.data;
        },
        close() {
            this.isOpen = false;
        }
    }
}
</script>

<style>

</style>
