<template>
    <div>
        <div class="grid">
            <div
                v-for="item in form.perguntasRespostas" :key="item.id"
                class="col-6 mb-3"
            >
                <label>{{ item.descricao }}</label>
                <Dropdown
                    v-if="item.tipo == 'CB'"
                    v-model="item.resposta"
                    :options="item.opcoes"
                    placeholder="Informe a resposta"
                />
                <InputText
                    v-if="item.tipo == 'DI'"
                    v-model.trim="item.resposta"
                />

                <InputNumber
                    v-if="item.tipo == 'IN'"
                    v-model.number="item.resposta"
                    :showButtons="true"
                />

                <MultiSelectQuestionario
                    v-if="item.tipo == 'ME'"
                    :optionItems="item.opcoes"
                    v-model="item.resposta"
                    placeholder="Informe a resposta"
                />
            </div>
            <div v-if="form.inserirAnexo" class="col-12">
                <div class="flex flex-column">
                    <Button
                        label="Anexar / Visualizar"
                        class=" p-button-outlined w-3"
                        :class="errorAnexo?.hasError ? 'p-button-danger' : ''"
                        @click="openDialogAnexo(form.etapa, form.origem)"
                    />
                    <small v-if="errorAnexo?.hasError" class="p-error">{{ errorAnexo?.message }}</small>
                </div>
            </div>
            <div v-if="form.origem === enumOrigem.AMBIENTE" class="col-12">
                <Accordion>
                    <AccordionTab header="Descrição do ambiente">
                        <div v-for="item in form.perguntasRespostas" :key="item.id">
                            <p>{{ item.descricao }}: <span class="font-bold">{{ typeof item.resposta === 'string' ? item.resposta?.split('|')?.join(', ') : item.resposta }}</span></p>
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
        </div>
        <AppAnexoDialog
            v-if="showAnexoDialog"
            :closable="true"
            tipoDeArquivoValido="image/*"
            v-model:visible="showAnexoDialog"
            v-model:origemId="form.origemId"
            :title="`Anexos ${titleChecklist}`"
            :tipo="tipoAnexo"
            :origem="origemAnexo"
            @onClickCloseAnexo="showAnexoDialog = false"
            @onUpload="onUploadAnexoDialog"
            @onDelete="onDeleteAnexoDialog"
        >
        </AppAnexoDialog>
    </div>
</template>

<script>
import OrigemAplicacaoRespostaEnum from '../../../../enums/OrigemAplicacaoRespostaEnum';
import MultiSelectQuestionario from './MultiSelectQuestionario.vue';

export default {
    props: {
        record: Object,
        titleChecklist: String,
        onUploadAnexo: Function,
        onDeleteAnexo: Function,
        errorAnexo: Object
    },
    components: {
        MultiSelectQuestionario
    },
    data() {
        return {
            form: this.record,
            enumOrigem: OrigemAplicacaoRespostaEnum,
            showAnexoDialog: false,
            origemAnexo: null,
            tipoAnexo: null
        }
    },
    methods: {
        openDialogAnexo(etapa, origem) {
            if (origem === this.enumOrigem.INICIAL) {
                this.tipoAnexo = `${etapa}_${this.enumOrigem.INICIAL}`;
            } else {
                this.tipoAnexo = `${etapa}_${this.enumOrigem.AMBIENTE}`;
            }

            this.origemAnexo = `CHECKLIST_${origem}`;
            this.showAnexoDialog = true;
        },
        onUploadAnexoDialog(anexo) {
            if (anexo) {
                this.onUploadAnexo();
            }
        },
        async onDeleteAnexoDialog(anexos) {
            await this.onDeleteAnexo(this.form, anexos);
        }
    }
}
</script>

<style>

</style>