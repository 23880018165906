const GrupoRiscosDesc = Object.freeze({
    FISICO: 'FÍSICO',
    QUIMICO: 'QUIMICO',
    BIOLOGICO: 'BIOLÓGICO',
    ERGONOMICO: 'ERGONÔMICO',
    ACIDENTE_MECANICO: 'ACIDENTE MECÂNICO',
    OUTRO: 'OUTRO'
})

export default GrupoRiscosDesc;
