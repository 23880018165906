<template>
    <div>
        <AppPaginatedGridNested
            ref="grid"
            :service="service"
            :parentName="parentName"
            :queryParams="queryParams"
            :defaultProps="queryParams"
            :expander="false"
            :isPaginated="false"
            :showButtonAlterar="true"
            :showButtonRemover="false"
            :showButtonAdicionarItem="false"
        >
            <template #header>
                <Message :closable="false">
                    <div class="flex flex-row">
                        <p class="mb-0 mr-4">Limite de Tolerância: {{ risco.limiteTolerancia ? risco.limiteTolerancia?.toFixed(2) : '-' }}</p>
                        <p class="mb-0 mr-4">Nível de Ação: {{ risco.nivelAcao ? risco.nivelAcao?.toFixed(2) : '-' }}</p>
                        <p class="mb-0 mr-4">Valor teto: {{ risco.valorTeto ? risco.valorTeto?.toFixed(2) : '-' }}</p>
                    </div>
                </Message>
            </template>

            <template #columns>
                <Column field="hierarquia.customerBranch" header="Unidade">
                    <template #editor="{ data }">
                        {{ data.hierarquia.customerBranch.name }}
                    </template>
                    <template #body="slotProps">
                        {{ slotProps?.data?.hierarquia?.customerBranch.name }}
                    </template>
                </Column>
                <Column field="hierarquia.customerDepartment" header="Setor">
                    <template #editor="{ data }">
                        {{ data.hierarquia.customerDepartment.name }}
                    </template>
                    <template #body="slotProps">
                        {{ slotProps?.data?.hierarquia?.customerDepartment.name }}
                    </template>
                </Column>
                <Column field="hierarquia.customerPosition" header="Cargo">
                    <template #editor="{ data }">
                        {{ data.hierarquia.customerPosition.name }}
                    </template>
                    <template #body="slotProps">
                        {{ slotProps?.data?.hierarquia?.customerPosition.name }}
                    </template>
                </Column>
                <Column field="tempoExposicao" header="Tempo Exposição (min)">
                    <template #editor="{ data }">
                        <InputNumber v-model="data.tempoExposicao" />
                    </template>
                    <template #body="slotProps">
                        {{ slotProps?.data?.tempoExposicao }}
                    </template>
                </Column>

                <Column field="medicao" header="Medição">
                    <template #editor="{ data }">
                        <InputNumber v-model="data.medicao" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" />
                    </template>
                    <template #body="slotProps">
                        {{ slotProps?.data?.medicao?.toFixed(2) }}
                    </template>
                </Column>

                <Column field="unidadeMedida" header="Unidade de medida">
                    <template #editor="{ data, field }">
                        <DropdownUnidadesMedida v-model="data[field]" @update:modelValue="data.unidadeMedidaId = data[field]?.id" />
                    </template>
                    <template #body="slotProps">
                        {{ slotProps?.data.unidadeMedida?.descricao }}
                    </template>
                </Column>

                <Column field="dataMedicao" header="Data Medição" headerStyle="width: 10rem;">
                    <template #editor="{ data, field }">
                        <Calendar class="w-10rem" v-model="data[field]" dateFormat="dd/mm/yy" :showIcon="true" placeholder="Data medição" />
                    </template>
                    <template #body="slotProps">
                        {{ $filters.formatDateOnly(slotProps?.data?.dataMedicao) }}
                    </template>
                </Column>

                <Column field="equipamento" header="Equipamento">
                    <template #editor="{ data, field }">
                        <DropdownEquipamentos v-model="data[field]" @update:modelValue="data.equipamentoId = data[field]?.id" />
                    </template>
                    <template #body="slotProps">
                        {{ slotProps?.data?.equipamento?.name }}
                    </template>
                </Column>

                <Column field="responsavelMedicao" header="Responsável">
                    <template #editor="{ data, field }">
                        <DropdownProfissionalSesmt
                            v-model="data[field]"
                            @update:modelValue="data.responsavelMedicaoId = data[field]?.id"
                            :filtrosExtras="null"
                        />
                    </template>
                    <template #body="slotProps">
                        {{ slotProps?.data?.responsavelMedicao?.nome }}
                    </template>
                </Column>
            </template>
        </AppPaginatedGridNested>
    </div>
</template>

<script>
import DropdownEquipamentos from '../../../../equipment/components/DropdownEquipamentos.vue';
import DropdownProfissionalSesmt from '../../../../profissionais-sesmt/components/DropdownProfissionalSesmt.vue';
import DropdownUnidadesMedida from '../../../../riscos/components/DropdownUnidadesMedida.vue';

export default {
    components: {
        DropdownEquipamentos,
        DropdownProfissionalSesmt,
        DropdownUnidadesMedida
    },
    props: {
        queryParams: {
            type: Object
        },
        risco: {
            type: Object
        },
        service: {
            type: Object,
            required: true
        },
        parentName: {
            type: String
        },
        labelBotaoAdicionarItem: {
            type: String
        }
    }
};
</script>