<template>
    <div>
        <AppPaginatedGridNested
            ref="grid"
            :service="service"
            :parentName="parentName"
            :queryParams="queryParams"
            :defaultProps="queryParams"
            :expander="false"
            :labelBotaoAdicionarItem="labelBotaoAdicionarItem"
            :isPaginated="false"
            :showButtonAlterar="true"
        >
            <template #header>
                <h4>{{ 'Fontes ou Circunstâncias' }}</h4>
            </template>

        <template #columns>
            <Column field="aplicacaoPerigoDescricaoRisco" header="Descrição de risco">
                <template #editor="{ data, field }">
                    <DropdownAplicPerigoDescRisco
                        v-model="data[field]"
                        :filtrosExtras="queryParams"
                        @update:modelValue="data.aplicacaoPerigoDescricaoRiscoId = data[field]?.id"
                    />
                </template>
                <template #body="slotProps">
                    {{ slotProps?.data?.aplicacaoPerigoDescricaoRisco?.riscoDescricaoRisco?.descricaoRisco?.nome }}
                </template>
            </Column>
            <Column field="fonteGeradora" header="Nome">
                <template #editor="{ data, field }">
                    <DropdownFonteGeradora
                        v-model="data[field]"
                        optionLabel="nome"
                        :options="fontesGeradoras"
                        @update:modelValue="data.fonteGeradoraId = data[field]?.id"
                    />
                </template>
                <template #body="slotProps">
                    {{ slotProps?.data?.fonteGeradora?.nome }}
                </template>
            </Column>
            <Column field="especificacaoFonteGeradora" header="Especificação">
                <template #editor="{ data, field }">
                    <InputText
                        v-model="data[field]"
                    />
                </template>
                <template #body="slotProps">
                    {{ slotProps?.data?.especificacaoFonteGeradora }}
                </template>
            </Column>
            <Column field="frequencia" header="Frequencia">
                <template #editor="{ data, field }">
                    <DropdownFrequencia
                        v-model="data[field]"
                        @update:modelValue="data.frequenciaId = data[field]?.id"
                    />
                </template>
                <template #body="slotProps">
                    {{ slotProps?.data?.frequencia.descricao }}
                </template>
            </Column>
            <Column v-if="isTipoAvaliacaoQuantitativo" header="" headerClass="w-1">
                <template #editor>
                    <Divider />
                </template>
                <template #body="slotProps">
                    <Button
                        icon="pi pi-plus"
                        class=" p-button-text"
                        label="Medição"
                        @click="openMedicaoDialog(slotProps)"
                    />
                </template>
            </Column>
        </template>
        </AppPaginatedGridNested>

        <DialogFonteGeradoraMedicao
            ref="dialogFonteGeradoraMedicao"
            title="MEDIÇÃO"
            :risco="risco"
        />
    </div>
</template>

<script>
import CrudService from '@/services/crud';
import moment from 'moment-timezone';
import TipoAvaliacaoRisco  from '../../../../../enums/TipoAvaliacaoRisco.js';
import DropdownAplicPerigoDescRisco  from './DropdownAplicPerigoDescRisco.vue';
import DialogFonteGeradoraMedicao from './DialogFonteGeradoraMedicao.vue';
import DropdownFonteGeradora from '../../../../fonte-geradora/components/DropdownFonteGeradora.vue';
import DropdownFrequencia from './DropdownFrequencia.vue';

export default {
    components: {
        DropdownAplicPerigoDescRisco,
        DialogFonteGeradoraMedicao,
        DropdownFonteGeradora,
        DropdownFrequencia
    },
    props: {
        tipoAvaliacaoRisco: {
            type: Number,
            required: true
        },
        queryParams: {
            type: Object,
        },
        risco: {
            type: Object
        },
        service: {
            type: Object,
            required: true
        },
        parentName: {
            type: String
        },
        labelBotaoAdicionarItem: {
            type: String
        }
    },
    computed: {
        isTipoAvaliacaoQuantitativo() {
            return TipoAvaliacaoRisco.CRITERIO_QUANTITATIVO === this.tipoAvaliacaoRisco;
        }
    },
    mounted() {
        this.$service = new CrudService('/fonte-geradora');
        this.load();
    },
    data() {
        return {
            fontesGeradoras: []
        }
    },
    methods: {
        async load() {
            this.fontesGeradoras = (await this.$service.findAll({})).data.items;
        },
        formatData(data) {
            if (data) return moment.tz(data, 'YYYY-MM-DD', 'America/Sao_Paulo').format('DD/MM/YYYY');
            return '';
        },
        async openMedicaoDialog(slotProps) {
            await this.$refs.dialogFonteGeradoraMedicao.open(slotProps);
        }
    }
}
</script>

<style>

</style>
