<template>
    <div>
        <Card class="mt-3">
            <template #title>
                <h4> {{ title }} </h4>
            </template>
            <template #content>
                <div class="mb-2">
                    <Checkbox
                        id="ausenciaPerigo"
                        v-model="form.ausenciaPerigo"
                        :binary="true"
                        @change="atualizarAusenciaPerigo"
                    />
                    <label class="ml-2" for="ausenciaPerigo">Ausência de perigo</label>
                </div>
                
                <div class="p-fluid painel painel-2">
                    <TabView :scrollable="true" ref="tabview" :activeIndex="active" @tab-click="onTabClick">
                        <TabPanel
                            v-for="tab of tabItems"
                            :key="tab.value"
                            :header="tab.nome"
                            :disabled="false"
                        >
                            <GridIdentificacao
                                :ref="`gridIdentificacao_${tab.value}`"
                                :ausenciaPerigo="form.ausenciaPerigo"
                                :etapa="etapa"
                            />
                        </TabPanel>
                    </TabView>
                </div>

                <NovoPerigoManual
                    ref="novoPerigoManual"
                    @onOpenDialogConfirmacaoPerigo="onOpenDialogConfirmacaoPerigo"
                    :grupoRisco="grupoRisco"
                />
            </template>
        </Card>

        <AppConfirmDialog
            v-if="showConfirmDialog"
            :loading="loadingConfirmAusenciaPerigo"
            v-model:visible="showConfirmDialog"
            title="Todos os Perigos marcados como 'Possuí Risco' serão desmarcados. Deseja continuar?"
            @onConfirm="salvarAusenciaPerigo"
            @onClose="cancelarAusenciaPerigo"
        >
        </AppConfirmDialog>

        <AppConfirmDialog
            v-model:visible="showConfirmDialogPerigo"
            :loading="false"
            title="Deseja adicionar o novo Perigo ao inventário?"
            @onConfirm="onClickConfirmNovoPerigo"
            @onClose="showConfirmDialogPerigo = false"
        />
    </div>
</template>

<script>
import AppConfirmDialog from '../../../../components/AppConfirmDialog.vue';
import GrupoRiscos from '../../../../enums/GrupoRiscos';
import GrupoRiscosDesc from '../../../../enums/GrupoRiscosDesc';
import eventBus from '../../../../event-bus';
import { showError } from '../../../../utils/Toast';
import AplicacaoRespostaPerigoService from '../../services/AplicacaoRespostaPerigoService';
import InventarioRiscoService from '../../services/InventarioRiscoService';
import GridIdentificacao from './GridIdentificacao.vue';
import NovoPerigoManual from './NovoPerigoManual.vue';

export default {
    props: {
        etapa: String
    },
    components: {
        GridIdentificacao,
        AppConfirmDialog,
        NovoPerigoManual
    },
    async created() {
        this.service = new InventarioRiscoService();
        this.aplicacaoRespostaPerigoService = new AplicacaoRespostaPerigoService();
        this.title = (await this.aplicacaoRespostaPerigoService.findTituloChecklist()).data;
    },
    mounted() {
        this.load(GrupoRiscos.FISICO);
        eventBus.on('onAfterTransfer', async () => {
            await this.load(GrupoRiscos.FISICO);
        });
        this.grupoRisco = GrupoRiscos.FISICO;
    },
    data() {
        return {
            title: null,
            active: 0,
            form: {},
            grupoRisco: null,
            service: null,
            showConfirmDialog: false,
            showConfirmDialogPerigo: false,
            loadingConfirmAusenciaPerigo: false,
            tabItems: [
                {
                    nome: GrupoRiscosDesc.FISICO,
                    value: GrupoRiscos.FISICO
                },
                {
                    nome: GrupoRiscosDesc.QUIMICO,
                    value: GrupoRiscos.QUIMICO
                },
                {
                    nome: GrupoRiscosDesc.BIOLOGICO,
                    value: GrupoRiscos.BIOLOGICO
                },
                {
                    nome: GrupoRiscosDesc.ERGONOMICO,
                    value: GrupoRiscos.ERGONOMICO
                },
                {
                    nome: GrupoRiscosDesc.ACIDENTE_MECANICO,
                    value: GrupoRiscos.ACIDENTE_MECANICO
                },
                {
                    nome: GrupoRiscosDesc.OUTRO,
                    value: GrupoRiscos.OUTRO
                }
            ],
        }
    },
    methods: {
        onOpenDialogConfirmacaoPerigo() {
            this.showConfirmDialogPerigo = true;
        },
        async onClickConfirmNovoPerigo() {
            const risco = this.$refs.novoPerigoManual?.risco;
            if (!risco) {
                const error = { message: 'Perigo não selecionado.' }
                showError(this.$toast, error);
                return;
            }
            const dto = {
                inventarioRiscoId: this.$route.params.id,
                riscoId: risco.id
            }
            await this.$refs.novoPerigoManual.onClickConfirmNovoPerigo(dto);
            this.showConfirmDialogPerigo = false;
            const activeTab = this.$refs.tabview.tabs[this.active].key;
            this.$refs[`gridIdentificacao_${activeTab}`].load(activeTab);
        },
        async atualizarAusenciaPerigo() {
            await this.service.save(this.form);
            if (this.form.ausenciaPerigo) {
                this.showConfirmDialog = true;
            }
        },
        async salvarAusenciaPerigo() {
            this.loadingConfirmAusenciaPerigo = true;
            for await (const item of this.tabItems) {
                await this.$refs[`gridIdentificacao_${item.value}`].alterarPossuiRiscoAusenciaPerigo();
            }
            this.loadingConfirmAusenciaPerigo = false;
            this.showConfirmDialog = false;
        },
        async cancelarAusenciaPerigo() {
            this.form.ausenciaPerigo = !this.form.ausenciaPerigo;
            await this.service.save(this.form);
            this.showConfirmDialog = false;
        },
        async load(param) {
            try {
                const { data } = await this.service.findByAplicacaoId(this.$route.params.id)
                this.form = data;
                if (this.$refs[`gridIdentificacao_${param}`]) {
                    await this.$refs[`gridIdentificacao_${param}`].load(param);
                }
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        onTabClick(event) {
            const tab = this.$refs.tabview.tabs[event.index];
            this.load(tab.key);
            this.active = event.index;
            this.grupoRisco = tab.key;
        }
    }
}
</script>

<style>

.btn-concluir {
    margin: 5px;
    max-width: 200px;
    height: 38px;
}

</style>