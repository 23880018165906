<template>
    <Card>
        <template #title>
            <h4>{{ title }}</h4>
        </template>
        <template #content>
            <GridCheckList
                ref="gridChecklistAmbiente"
                :service="service"
            >
                <template #columns>
                    <Column field="status" header="Status checklist" headerStyle="width: 15em">
                        <template #body="slotProps">
                            <i
                                class="pi pi-circle-fill"
                                :style="statusChecklist(slotProps.data.status)"
                                v-tooltip.rigth="statusChecklistName(slotProps.data.status)"
                            />
                        </template>
                    </Column>
                    <Column field="unidade.name" header="Unidade" headerStyle="width: 30%" />
                    <Column field="setor.name" header="Setor" headerStyle="width: 30%" />
                    <Column header="Ações">
                        <template #body="slotProps">
                            <Button
                                :loading="!!loadItemsQuestionario[loadItemsQuestionario.indexOf(slotProps.data.id)]"
                                :label="slotProps.data.status == statusAplicacaoLevantamentoEnum.NAO_INICIADO ? 'Iniciar' : 'Visualizar'"
                                class="p-button-sm p-button-outlined"
                                @click="iniciar(slotProps)"
                            />
                        </template>
                    </Column>
                </template>
            </GridCheckList>
        </template>
    </Card>
</template>

<script>
import OrigemAplicacaoRespostaEnum from '../../../../enums/OrigemAplicacaoRespostaEnum';
import StatusAplicacaoLevantamentoEnum from '../../../../enums/StatusAplicacaoLevantamentoEnum';
import eventBus from '../../../../event-bus';
import AplicacaoQuestionarioAmbienteService from '../../services/AplicacaoQuestionarioAmbienteService';
import GridCheckList from './GridCheckList.vue';

export default {
    emits: ['onLoadDialog', 'onCreateBeforeOpen'],
    components: {
        GridCheckList
    },
    data() {
        return {
            title: null,
            service: null,
            statusAplicacaoLevantamentoEnum: StatusAplicacaoLevantamentoEnum,
            origem: OrigemAplicacaoRespostaEnum.AMBIENTE,
            loadItemsQuestionario: []
        }
    },
    created() {
        this.service = new AplicacaoQuestionarioAmbienteService();
    },
    async mounted() {
        const { data: title } = await this.service.findTituloChecklist(this.$route.params.id);
        this.title = title;
        await this.load();
        eventBus.on('onAfterTransfer', async () => {
            await this.load();
        });
    },
    methods: {
        async load() {
            if (this.$refs.gridChecklistAmbiente) {
                await this.$refs.gridChecklistAmbiente.load();
            }
        },
        statusChecklist(status) {
            const Status = {
                'NAO_INICIADO': 'color: var(--grey-500)',
                'EM_ANDAMENTO': 'color: var(--yellow-500)',
                'FINALIZADO': 'color: var(--green-500)'
            }
            return Status[status]
        },
        statusChecklistName(status) {
            const Status = {
                'NAO_INICIADO': 'Não Iniciado',
                'EM_ANDAMENTO': 'Em Andamento',
                'FINALIZADO': 'Finalizado'
            }
            return Status[status]
        },
        iniciar(slotProps) {
            if (slotProps.data.status == this.statusAplicacaoLevantamentoEnum.NAO_INICIADO) {
                this.$emit('onCreateBeforeOpen', slotProps.data, this.origem);
                return;
            }
            this.$emit('onLoadDialog', slotProps.data, this.origem);
        },
        iniciarLoadingAcao(record) {
            this.loadItemsQuestionario.push(record.id);
        },
        finalizarLoadingAcao() {
            this.loadItemsQuestionario = [];
        }
    }
}
</script>

<style>

</style>