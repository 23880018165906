<template>
    <Dialog :visible="isOpen" class="p-fluid" :style="{ width: '500px', height: '400px' }" header="Medida de controle" :modal="true">
        <AppPaginatedGrid
            ref="gridDescricaoMedidaControle"
            :service="service"
            :filtrosExtras="filtrosExtras"
            :selection="false"
            :expander="false"
            :showActionItems="false"
            :showButtonLimparFiltros="false"
            :tipo="tipoFiltroEnum.SISTEMA"
            :modulo="moduloFiltroEnum.MEDIDA_CONTROLE"
            descricao="FILTRO PADRÃO MEDIDA CONTROLE"
            nomeTelaDoManual="medida-controle-list"
        >
            <template #columns>
                <Column field="descricao" header="Descrição" />
                <Column field="acoes" header="Ações">
                    <template #body="slotProps">
                        <Button icon="pi pi-copy" class="p-button-sm p-button-outlined" @click="copyDescricao(slotProps)" />
                    </template>
                </Column>
            </template>
        </AppPaginatedGrid>
        <template #footer>
            <Button label="Sair" icon="pi pi-times" class="p-button-text" @click="onCloseDialog" />
        </template>
    </Dialog>
</template>

<script>
import BaseService from '../../../../../services/BaseService';
import { montarFiltrosExtras } from '../../../../../utils/FiltrosExtras';
import ModuloFiltroEnum from '../../../../../enums/ModuloFiltroEnum';
import TipoFiltroEnum from '../../../../../enums/TipoFiltroEnum';
export default {
    emits: ['sendDescricaoCopy'],
    data() {
        return {
            isOpen: false,
            service: null,
            filtrosExtras: {}
        };
    },
    created() {
        this.service = new BaseService('/medida-adm-engenharia');
    },
    computed: {
        tipoFiltroEnum() {
            return TipoFiltroEnum;
        },
        moduloFiltroEnum() {
            return ModuloFiltroEnum;
        }
    },
    methods: {
        async open(tipoMedidaControle) {
            this.isOpen = true;
            this.createFiltersWhere(tipoMedidaControle);
            this.filtrosExtras = montarFiltrosExtras(this.filtrosExtras);
            await this.load();
        },
        onCloseDialog() {
            this.isOpen = false;
        },
        createFiltersWhere(tipoMedidaControle) {
            const where = [
                {
                    prop: 'medida.tipo',
                    operator: 'equal',
                    values: [tipoMedidaControle]
                }
            ];
            this.filtrosExtras = {
                where
            };
        },
        async load() {
            setTimeout(async () => await this.$refs.gridDescricaoMedidaControle?.load(), 0);
        },

        copyDescricao(props) {
            this.$emit('sendDescricaoCopy', props.data?.descricao);
            this.onCloseDialog();
        }
    }
};
</script>