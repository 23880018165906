<template>
    <div>
        <Dialog
            v-model:visible="isOpen"
            class="p-fluid"
            :style="{ width: '80%', height: 'auto' }"
            :draggable="false"
            :closable="true"
            :modal="true"
            @after-hide="onAfterHide"
        >
            <template #header>
                <h3> <i v-tooltip="'Gerenciamento de checklist'" class="pi pi-info-circle"></i> {{ title }} </h3>
            </template>
            <div class="grid">
                <div class="col-12">
                    <Panel>
                        <template #header>
                            <div
                                class="justify-content-center"
                                style="text-align: center; width: 100%"
                            >
                                <h3 >Cliente: {{ unidade.customerId }} </h3>
                                <h3>Unidade: {{ unidade.name }} </h3>
                            </div>
                        </template>
                        
                        <PerguntasQuestionario
                            ref="pergunta"
                            :record="form"
                            :titleChecklist="title"
                            :errorAnexo="errorAnexo"
                            :onUploadAnexo="onUploadAnexo"
                            :onDeleteAnexo="onDeleteAnexo"
                        />
                    </Panel>
                </div>
            </div>
            <template #footer>
                <div class="flex justify-content-end">
                    <div>
                        <Button class="p-button p-button-outlined" icon="pi pi-arrow-left" label="Voltar" @click="close"/>
                        <Button :loading="loadingSalvarQuestionario" class="p-button ml-2" icon="pi pi-check" label="Salvar" @click="save"/>
                        <Button 
                            :loading="loadingConcluirQuestionario" 
                            class="bg-green-400 p-button ml-2 mr-3" 
                            icon="pi pi-check-circle" label="Conlcuir" 
                            @click="salvarEConcluir"
                        />
                    </div>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { showError, showInfo, showSuccess } from '../../../../utils/Toast';
import PerguntasQuestionario from './PerguntasQuestionario.vue';

export default {
    emits: ['onAfterSave'],
    components: {
        PerguntasQuestionario
    },
    props: {
        service: {
            type: Object,
            require: true
        }
    },
    data() {
        return {
            isOpen: false,
            form: {
            },
            unidade: {},
            title: null,
            loadingSalvarQuestionario: false,
            loadingConcluirQuestionario: false,
            errorAnexo: {}
        }
    },
    created() {
        this.$service = this.service;
    },
    methods: {
        open() {
            this.isOpen = true;
        },
        onAfterSave(origem) {
            this.$emit('onAfterSave', origem);
        },
        close() {
            this.isOpen = false;
            this.onAfterSave(this.form.origem);
        },
        onAfterHide() {
            this.onAfterSave(this.form.origem);
        },
        montaPerguntasRespostas(data) {
            return data.map(el => {
                return {
                    id: el.id,
                    perguntaId: el.perguntaId,
                    resposta: el.perguntaSesmt.tipo === 'IN' && el.resposta ? Number(el.resposta): el.resposta,
                    descricao: el.perguntaSesmt.descricao,
                    mensagemAjuda: el.perguntaSesmt.mensagemAjuda,
                    tipo: el.perguntaSesmt.tipo,
                    opcoes: el.perguntaSesmt.opcoes
                }
            });
        },
        async load({ origemId, origem, unidade, etapa }) {
            const { data } = await this.$service.findByOrigem({ origemId, origem, etapa });
            this.title = data[0]?.questionarioSesmt?.nome;
            const perguntasRespostas = this.montaPerguntasRespostas(data);
            this.unidade = unidade;
            this.form.origemId = origemId;
            this.form.origem = origem;
            this.form.inventarioRiscoId = this.$route.params.id;
            this.form.perguntasRespostas = perguntasRespostas;
            this.form.questionarioSesmtId = data[0]?.questionarioSesmtId;
            this.form.etapa = etapa;
            this.form.inserirAnexo = data[0]?.questionarioSesmt?.inserirAnexo;
            this.form.anexoObrigatorio = data[0]?.questionarioSesmt?.anexoObrigatorio;
            this.open();
        },
        async save() {
            try {
                this.loadingSalvarQuestionario = true;
                this.form.perguntasRespostas = this.$refs.pergunta.form.perguntasRespostas;
                this.concatenarRespostas();
                await this.$service.saveByQuestionario(this.form);
                showSuccess(this.$toast, 'Registro salvo com sucesso!');
                this.loadingSalvarQuestionario = false;
                this.close();
            } catch (error) {
                this.loadingSalvarQuestionario = false;
                showError(this.$toast, error);
            }
        },
        concatenarRespostas() {
            this.form.perguntasRespostas = this.$refs.pergunta.form.perguntasRespostas;
            let retorno = '';
            for (const resposta of this.form.perguntasRespostas) {
                if (retorno == '') {
                    retorno = resposta.descricao + ': ' + resposta.resposta;
                } else {
                    retorno = retorno + ' - ' + resposta.descricao + ': ' + resposta.resposta;
                }
            }
            this.form.respostasConcatenadas = retorno;
        },
        async salvarEConcluir() {
            try {
                this.loadingConcluirQuestionario = true;
                this.form.perguntasRespostas = this.$refs.pergunta.form.perguntasRespostas;
                this.concatenarRespostas();
                await this.$service.concluirQuestionario(this.form);
                this.loadingConcluirQuestionario = false;
                showSuccess(this.$toast, 'Questionário concluído!');
                this.close();
            } catch (error) {
                this.loadingConcluirQuestionario = false;
                this.errorAnexo = {
                    hasError: true,
                    message: error?.response?.data?.message
                };
            }
        },
        onUploadAnexo() {
            this.errorAnexo = {
                hasError: false,
                message: null
            };
        },
        async onDeleteAnexo(record, anexos) {
            if (!anexos?.length) {
                try {
                    await this.$service.alterarQuestionarioParaEmAndamento(record);
                    this.onAfterSave(this.form.origem);
                    showInfo(this.$toast, 'Status do questionário alterado para em andamento.');
                } catch (error) {
                    showError(this.$toast, error);
                }

                return;
            }
        }
    }
}
</script>

<style>

</style>