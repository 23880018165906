import BaseService from "../../../services/BaseService";
import { getClientBase } from "../../../services/http";

export default class AplicacaoQuestionarioAmbienteService extends BaseService {
    constructor() {
        super('aplicacao-questionario-ambiente');
        this.path = 'aplicacao-questionario-ambiente';
    }

    findByInventarioRisco(id) {
        return getClientBase().get(`${this.path}/find-by-inventario-risco/${id}`);
    }

    findTituloChecklist(id) {
        return getClientBase().get(`${this.path}/get-titulo-checklist/${id}`);
    }
}
