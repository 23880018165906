<template>
    <MultiSelect
        class="app-multiselect"
        v-model="value"
        :options="optionItems"
    >
    </MultiSelect>
</template>

<script>
export default {
    props: {
        optionItems: Array,
        modelValue: String || Array
    },
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue?.length ? this.modelValue.split('|') : null;
            },
            set(value) {
                this.$emit('update:modelValue', value?.length ? value.filter(item => item !== '' && item !== null && item !== undefined).join('|') : null);
            }
        }
    }
}
</script>

<style>
.app-multiselect .p-multiselect-label-container {
    padding: 0.25rem;
}
</style>