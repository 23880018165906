import BaseService from "../../../services/BaseService";
import { getClientBase } from "../../../services/http";
import OrigemAplicacaoRespostaEnum from '../../../enums/OrigemAplicacaoRespostaEnum';

export default class AplicacaoRespostaService extends BaseService {
    constructor(path) {
        super(path);
        this.path = path;
    }

    alterarQuestionarioParaEmAndamento(record) {
        if (record.origem == OrigemAplicacaoRespostaEnum.INICIAL) {
            return getClientBase().patch(`${this.path}/questionario-inicial/alterar-status/${record.origemId}`);
        }

        if (record.origem == OrigemAplicacaoRespostaEnum.AMBIENTE) {
            return getClientBase().patch(`${this.path}/questionario-ambiente/alterar-status/${record.origemId}`);
        }
    }

    concluirQuestionario(record) {
        if (record.origem == OrigemAplicacaoRespostaEnum.INICIAL) return getClientBase().patch(`${this.path}/concluir-questionario-inicial/${record.origemId}`, record);
        if (record.origem == OrigemAplicacaoRespostaEnum.AMBIENTE) return getClientBase().patch(`${this.path}/concluir-questionario-ambiente/${record.origemId}`, record);
    }

    startQuestionario(record) {
        return getClientBase().post(this.path, record);
    }

    saveByQuestionario(record) {
        return getClientBase().patch(`${this.path}/${record.origemId}`, record)
    }

    findByOrigem({ origemId, origem, etapa }) {
      return getClientBase().get(`${this.path}/aplicacao-respostas/origem-id/${origemId}/origem/${origem}/etapa/${etapa}`)
    }

}
