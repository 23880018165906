const MedidaControle = Object.freeze([
    {
        label: 'EPC',
        value: 'EPC'
    },
    {
        label: 'EPI',
        value: 'EPI'
    },
    {
        label: 'Medida Administrativa',
        value: 'ADMINISTRATIVA'
    },
    {
        label: 'Medida de engenharia',
        value: 'ENGENHARIA'
    }
]);

export default MedidaControle;
