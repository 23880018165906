<template>
    <div>
        <InputText class="input-copy" placeholder="Descriçao" v-model="descricao" />
        <Button icon="pi pi-search" class="p-button-primary button-copy" v-if="tipoMedida" @click="abrirDialogGridMedidaControle" />
        <DialogGridMedidaControle
            ref="dialogGridMedidaControle"
            :isOpen="isOpen"
            @onCloseDialog="isOpen = false"
            @sendDescricaoCopy="sendDescricaoCopy"
        />
    </div>
</template>

<script>
import DialogGridMedidaControle from './DialogGridMedidaControle.vue';

export default {
    props: {
        modelValue: {
            type: String
        },
        tipoMedidaControle: String
    },
    components: {
        DialogGridMedidaControle
    },
    data(){
        return{
            isOpen: false,
            tipoMedida: null
        }
    },
    watch: {
        tipoMedidaControle(value) {
            this.tipoMedida = value;
        }
    },
    computed: {
        descricao: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    methods: {
        async abrirDialogGridMedidaControle() {
            this.isOpen = false;
            setTimeout(() => this.$refs.dialogGridMedidaControle.open(this.tipoMedida), 0);

        },
        sendDescricaoCopy(desc) {
            this.descricao = desc;
        }
    }
}
</script>

<style>
.input-copy{
    width: 80% !important;
    margin-right: 5px;
}

.button-copy{
    height: 100%;
    width: 10%
}
</style>