import BaseService from "../../../services/BaseService";
import { getClientBase } from "../../../services/http";

export default class AplicacaoRespostaPerigoService extends BaseService {
    constructor() {
        super('aplicacao-resposta-perigo');
        this.path = 'aplicacao-resposta-perigo';
    }

    adicionarPerigoInventarioRisco(payload) {
        return getClientBase().post(`${this.path}/novo-perigo`, payload);
    }

    findByInventarioRisco(id, etapa, query) {
        return getClientBase().get(`${this.path}/find-by-inventario-risco/${id}/etapa/${etapa}`, {
            params: {
                ...query
            }
        });
    }

    findTituloChecklist() {
        return getClientBase().get(`${this.path}/get-titulo-checklist/`);
    }
}
