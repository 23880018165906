<template>
    <div>
        <AppLoadingWrapper v-if="loading" />
        <DataTable
            v-if="!loading"
            dataKey="id"
            :value="records"
            class="p-datatable-sm"
            responsiveLayout="stack"
            breakpoint="640px"
        >
            <template #empty>
                <div class="flex flex-column justify-content-center align-items-center p-2">
                    <span style="font-size: 2rem; color: var(--gray-500);">Nenhum dado encontrado.</span>
                </div>
            </template>

            <slot name="columns"></slot>
        </DataTable>
    </div>
</template>

<script>
import { showError } from '../../../../utils/Toast';
export default {
    props: {
        service: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            records: []
        }
    },
    mounted() {
        this.$service = this.service;
    },
    methods: {
        async load() {
            try {
                this.loading = true;
                const { data } = await this.$service.findByInventarioRisco(this.$route.params.id);
                this.records = data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                showError(this.$toast, error);
            }
        }
    }
}
</script>

<style>

</style>