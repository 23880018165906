<template>
    <div class="flex-column mt-4">
        <div class="flex">
            <h4>Adicionar Outros Perigos</h4>
        </div>

        <div class="flex flex-row align-items-center gap-2 w-5">
            <div class="flex flex-column flex-grow-1">
                <label  for="dropdownRiscos">Perigos</label>
                <DropdownRiscos
                    id="dropdownRiscos"
                    :disabled="!inserindo"
                    v-model="risco"
                    :filtrosExtras="filtrosExtrasLocal"
                />
            </div>
            <div class="flex flex-column flex-grow-none">
                <Button
                    v-if="!inserindo"
                    icon="pi pi-plus"
                    v-tooltip.bottom="'Novo perfil'"
                    class="mt-4 p-button-rounded p-button-primary p-button"
                    @click="inserindo = !inserindo"
                />
                <div v-else class="flex flex-row flex-grow-none gap-2">
                    <Button
                        :disabled="loadingSalvarPerigo"
                        icon="pi pi-times"
                        v-tooltip.bottom="'Cancelar'"
                        class="mt-4 p-button-outlined p-button-rounded p-button-danger"
                        @click="calcelarInsercao"
                    />
                    <Button
                        :loading="loadingSalvarPerigo"
                        icon="pi pi-check"
                        v-tooltip.bottom="'Salvar'"
                        class="mt-4 p-button-outlined p-button-rounded p-button-success"
                        @click="onOpenDialogConfirmacao"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { montarFiltrosExtras } from '../../../../utils/FiltrosExtras';
import { showError, showSuccess } from '../../../../utils/Toast';
import DropdownRiscos from '../../../riscos/components/DropdownRiscos.vue'
import AplicacaoRespostaPerigoService from '../../services/AplicacaoRespostaPerigoService';

export default {
    components: {
        DropdownRiscos
    },
    props: {
        grupoRisco: Number
    },
    data() {
        return {
            inserindo: false,
            loadingSalvarPerigo: false,
            showConfirmDialog: false,
            risco: null,
            service: null,
            filtrosExtras: {
                where: [
                    {
                        prop: 'checklist',
                        operator: 'equal',
                        values: [false]
                    },
                    {
                        prop: 'grupo_risco',
                        operator: 'equal',
                        values: [this.grupoRisco]
                    }
                ]
            },
            filtrosExtrasLocal: {}
        }
    },
    watch: {
        grupoRisco(value) {
            this.filtrosExtras = {
                where: [
                    {
                        prop: 'checklist',
                        operator: 'equal',
                        values: [false]
                    },
                    {
                        prop: 'grupo_risco',
                        operator: 'equal',
                        values: [value]
                    }
                ]
            }
            this.filtrosExtrasLocal = montarFiltrosExtras(this.filtrosExtras);
        }
    },
    mounted() {
        this.service = new AplicacaoRespostaPerigoService();
        this.filtrosExtrasLocal = montarFiltrosExtras(this.filtrosExtras);
    },
    methods: {
        async onOpenDialogConfirmacao() {
            this.$emit('onOpenDialogConfirmacaoPerigo');
        },
        calcelarInsercao() {
            this.inserindo = !this.inserindo;
            this.risco = null;
        },
        async onClickConfirmNovoPerigo(dto) {
            try {
                this.loadingSalvarPerigo = true;
                await this.service.adicionarPerigoInventarioRisco(dto);
                this.loadingSalvarPerigo = false;
                this.inserindo = false;
                this.risco = null;
                showSuccess(this.$toast, 'Perigo adicionado.');
            } catch (error) {
                showError(this.$toast, error);
                this.loadingSalvarPerigo = false;
                this.inserindo = false;
                this.risco = null;
            }
        }
    }
}
</script>

<style>

</style>