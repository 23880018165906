<template>
    <AppLoadingWrapper v-if="loading" />
    <DataTable
        v-if="!loading"
        ref="table"
        :value="records"
        class="p-datatable-sm"
        responsiveLayout="stack"
        breakpoint="640px"
        v-model:expandedRows="expandedRows"
        dataKey="id"
    >
        <Column :expander="true" headerStyle="width: 3em"></Column>
        <Column field="possuiRisco" header="Possui risco" headerStyle="width: 15em">
            <template #body="slotProps">
                <SelectButton
                    :disabled="ausenciaPerigo"
                    :unselectable="false"
                    class="w-full"
                    v-model="slotProps.data.possuiRisco"
                    :options="[{ label: 'Sim', value: true }, { label: 'Não', value: false }]"
                    optionLabel="label"
                    optionValue="value"
                    @change="save(slotProps.data, 'possuiRisco')"
                />
            </template>
        </Column>
        <Column field="risco.nome" header="Perigos"></Column>


        <template #expansion="slotProps">
            <div class="checklist-body">
                <div class="items">
                    <h4>Critério de avaliação</h4>
                    <Dropdown
                        id="tipoAvaliacaoRisco"
                        class="w-2"
                        v-model="slotProps.data.tipoAvaliacaoRisco"
                        :options="tiposAvaliacoes"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Selecione..."
                        @blur="save(slotProps.data, 'tipoAvaliacaoRisco')"
                    />
                </div>
                <DescricaoRiscoGrid
                    class="items"
                    :service="serviceDescricaoRisco"
                    :parentName="'descricao-perigo'"
                    :queryParams="{
                        inventarioRiscoId: slotProps.data.inventarioRiscoId,
                        aplicacaoRespostaPerigoId: slotProps.data.id,
                        aplicacaoRespostaPerigoVersao: slotProps.data.versao,
                        etapa,
                        riscoId: slotProps.data.riscoId
                    }"
                />

                <FonteGeradoraGrid
                    class="items"
                    :tipoAvaliacaoRisco="slotProps.data.tipoAvaliacaoRisco"
                    :service="serviceFonteGeradora"
                    :parentName="'fonte-geradora-perigo'"
                    :queryParams="{
                        inventarioRiscoId: slotProps.data.inventarioRiscoId,
                        aplicacaoRespostaPerigoId: slotProps.data.id,
                        aplicacaoRespostaPerigoVersao: slotProps.data.versao,
                        etapa
                    }"
                    :risco="slotProps.data.risco"
                    :labelBotaoAdicionarItem="'Inserir fonte geradora'"
                />

                <div class="items">
                    <h4>Análise Qualitativa</h4>
                    <InputText
                        v-model="slotProps.data.analiseQualitativa"
                        @blur="save(slotProps.data, 'analiseQualitativa')"
                    />
                </div>

                <div class="items">
                    <h4>Quantificação complementar(GHE)</h4>
                    <SelectButton
                        class="w-4"
                        v-model="slotProps.data.quantificacaoComplementarGhe"
                        :options="[{ label: 'Sim', value: 'sim' }, { label: 'Não', value: 'nao' }]"
                        optionLabel="label"
                        optionValue="value"
                        @change="save(slotProps.data, 'quantificacaoComplementarGhe')"
                    />
                </div>


                <div class="items">
                    <MedidasControleGrid
                        @onIncluirAnexo="onIncluirAnexo"
                        class="items"
                        :service="serviceMedidaControle"
                        :parentName="'medida-controle-perigo'"
                        :queryParams="{
                            inventarioRiscoId: slotProps.data.inventarioRiscoId,
                            aplicacaoRespostaPerigoId: slotProps.data.id,
                            aplicacaoRespostaPerigoVersao: slotProps.data.versao,
                            etapa
                        }"
                        :labelBotaoAdicionarItem="'Inserir medida de controle'"
                    />
                </div>

                <div class="items">
                    <h4>Recomendação Orientação</h4>
                    <InputText
                        v-model="slotProps.data.recomendacaoOrientacao"
                        @blur="save(slotProps.data, 'recomendacaoOrientacao')"
                    />
                </div>

                <div class="items">
                    <h4>Anexos</h4>
                    <Button
                        class="w-3 bg-primary-reverse"
                        label="Anexar / Visualizar"
                        @click="onIncluirAnexo(slotProps.data.id, origemAnexo, this.origemAnexoEnum.CHECKLIST_PERIGO)"
                    />
                </div>
            </div>
        </template>

        <template #empty>
            Nenhum registro encontrado
        </template>
    </DataTable>

    <AppAnexoDialog
            v-if="showAnexoDialog"
            v-model:visible="showAnexoDialog"
            title="Medidas de controle existentes"
            :tipo="tipoAnexo"
            :origem="tipoAnexo"
            v-model:origemId="origemAnexoId"
            @onClickCloseAnexo="showAnexoDialog = false"
        >
    </AppAnexoDialog>
</template>

<script>
import NestedService from '../../../../services/NestedService';
import { montarFiltrosExtras } from '../../../../utils/FiltrosExtras';
import { showError } from '../../../../utils/Toast';
import AplicacaoRespostaPerigoService from '../../services/AplicacaoRespostaPerigoService';
import DescricaoRiscoGrid from './checklist-perigo/DescricaoRiscoGrid.vue';
import FonteGeradoraGrid from './checklist-perigo/FonteGeradoraGrid.vue';
import MedidasControleGrid from './checklist-perigo/MedidasControleGrid.vue';
import OrigemAnexoEnum from '../../../../enums/OrigemAnexoEnum';
import TipoAnexoEnum from '../../../../enums/TipoAnexoEnum';
import TipoAvaliacaoRisco, { TipoAvaliacaoRiscoLabelEnum } from '../../../../enums/TipoAvaliacaoRisco';

export default {
    inheritAttrs: false,
    components: {
        DescricaoRiscoGrid,
        FonteGeradoraGrid,
        MedidasControleGrid
    },
    props: {
        ausenciaPerigo: {
            type: Boolean,
            default: false
        },
        etapa: String
    },
    data() {
        return {
            showAnexoDialog: false,
            tipoAnexo: TipoAnexoEnum.CHECKLIST_PERIGO,
            origemAnexo: null,
            origemAnexoEnum: OrigemAnexoEnum,
            origemAnexoId: null,
            loading:false,
            expandedRows: [],
            records: [],
            service: null,
            serviceDescricaoRisco: null,
            serviceFonteGeradora: null,
            filtrosExtrasLocal: {
                where: [
                    {
                        prop: 'risco.grupo_risco',
                        operator: 'equal',
                        values: []
                    }
                ],
            },
            timeout: null,
            tiposAvaliacoes: [
                { 
                    label: TipoAvaliacaoRiscoLabelEnum.get(TipoAvaliacaoRisco.CRITERIO_QUANTITATIVO),
                    value: TipoAvaliacaoRisco.CRITERIO_QUANTITATIVO
                }, { 
                    label: TipoAvaliacaoRiscoLabelEnum.get(TipoAvaliacaoRisco.CRITERIO_QUALITATIVO),
                    value: TipoAvaliacaoRisco.CRITERIO_QUALITATIVO
                },
                { 
                    label: TipoAvaliacaoRiscoLabelEnum.get(TipoAvaliacaoRisco.FAIXA_DE_CONFORTO),
                    value: TipoAvaliacaoRisco.FAIXA_DE_CONFORTO
                }
            ],
        }
    },
    created() {
        this.service = new AplicacaoRespostaPerigoService();
        this.serviceDescricaoRisco = new NestedService('/aplicacao-perigo-descricao-risco');
        this.serviceFonteGeradora = new NestedService('/aplicacao-perigo-fonte-geradora');
        this.serviceMedidaControle = new NestedService('/aplicacao-perigo-medida-controle');
    },
    methods: {
        async alterarPossuiRiscoAusenciaPerigo() {
            for await (const record of this.records) {
                record.possuiRisco = false;
                await this.save(record, 'possuiRisco');
            }
        },
        onIncluirAnexo(origemId, origem, tipo) {
            this.tipoAnexo = tipo;
            this.origemAnexo = origem;
            this.origemAnexoId = origemId;
            this.showAnexoDialog = true;
        },
        async save(data, field) {
            const { id } = data;
            const value = `${typeof data[field] == "boolean" ? data[field] : `"${data[field]}"` }`
            const objetoDinamico = `
                {
                    "${field}": ${value}
                }
            `

            const entity = {
                id,
                respostaPerigo: {
                    ...JSON.parse(objetoDinamico)
                },
                etapa: this.etapa
            };
            try {
                await this.service.save(entity);
            } catch (error) {
                showError(this.$toast, error);
            }

        },
        async load(index) {
            this.filtrosExtrasLocal.where[0].values.push(index);
            this.filtrosExtrasLocal = montarFiltrosExtras(this.filtrosExtrasLocal);
            try {
                this.loading = true;
                const { data } = await this.service.findByInventarioRisco(this.$route.params.id, this.etapa, { filtrosExtras: this.filtrosExtrasLocal });
                this.records = data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                showError(this.$toast, error);
            }
        },
        expandCheckeds() {
            this.expandedRows = this.records.filter(p => p.possuiRisco);
        }
    }
}
</script>

<style>

.checklist-body {
    padding: 15px;
}

.items {
    margin-bottom: 30px;
}

</style>
