<template>
    <Card class="mt-3">
        <template #title>
            <h4>Conclusão etapa</h4>
        </template>
        <template #content>
            <div class="flex flex-row">
                <div class="flex flex-column col-5">
                    <label for="responsavelSesmitMultiSelect">Responsável</label>
                    <ResponsavelSesmitMultiSelect v-model="responsaveis" @hide="save" id="responsavelSesmitMultiSelect" />
                </div>
                <div class="flex flex-column col-4">
                    <label for="dataConclusao">Data de conclusão</label>
                    <Calendar
                        id="dataConclusao"
                        v-model="form.dataConclusao"
                        dateFormat="dd/mm/yy"
                        :showIcon="true"
                        placeholder="Data de conclusão"
                        :class="{ 'p-invalid': form.dataConclusao > new Date() }"
                    />
                    <small class="p-error" v-if="form.dataConclusao > new Date()"
                        >A data de conclusão precisa ser menor ou igual que a data de hoje.</small
                    >
                </div>
            </div>
            <div class="flex flex-row">
                <div class="flex flex-column col-5">
                    <label for="acompanhante">Acompanhante</label>
                    <InputText id="acompanhante" v-model="form.acompanhante" @change="alterAcompanhante" />
                </div>
                <div class="flex flex-column col-5">
                    <label for="funcaoAcompanhante">Função Acompanhante</label>
                    <InputText id="funcaoAcompanhante" v-model="form.funcaoAcompanhante" @change="alterFuncaoAcompanhante" />
                </div>
            </div>
            <div class="flex flex-column col-10">
                <label for="observacao">Observação</label>
                <Textarea
                    :rows="6"
                    v-model="form.observacao"
                    @blur="alterarObservacao"
                />
            </div>
            <div class="flex flex-row">
                <div class="flex flex-column col-5">
                    <Button
                        class="w-3 bg-primary-reverse"
                        label="Anexar / Visualizar"
                        @click="onIncluirAnexo(this.aplicacaoLevantamentoId)"
                    />
                </div>
            </div>
        </template>
    </Card>
    <AppAnexoDialog
        v-if="showAnexoDialog"
        v-model:visible="showAnexoDialog"
        title="Medidas de controle existentes"
        :tipo="tipoAnexo"
        :origem="origemAnexo"
        v-model:origemId="origemAnexoId"
        @onClickCloseAnexo="showAnexoDialog = false"
    >
    </AppAnexoDialog>
</template>

<script>
import BaseService from '../../../../services/BaseService';
import { showError } from '../../../../utils/Toast';
import { montarFiltrosExtras } from '../../../../utils/FiltrosExtras';
import ResponsavelSesmitMultiSelect from '../checklist/checklist-digital/ResponsavelSesmitMultiSelect.vue';
import InventarioRiscoService from '../../services/InventarioRiscoService';
import { getClientBase } from '../../../../services/http';
import OrigemAnexoEnum from '../../../../enums/OrigemAnexoEnum';
import TipoAnexoEnum from '../../../../enums/TipoAnexoEnum';

export default {
    props: {
        etapa: String
    },
    components: {
        ResponsavelSesmitMultiSelect
    },
    data() {
        return {
            showAnexoDialog: false,
            tipoAnexo: null,
            origemAnexo: null,
            origemAnexoId: null,
            form: {},
            aplicacaoLevantamentoId: null,
            responsaveis: [],
            service: null,
            serviceInventarioRisco: null,
            filtrosExtrasLocal: null,
            filtrosExtras: {
                where: [
                    {
                        prop: 'inventario_risco_responsavel.etapa',
                        operator: 'equal',
                        values: [this.etapa]
                    },
                    {
                        prop: 'inventario_risco_responsavel.inventarioRiscoId',
                        operator: 'equal',
                        values: [this.$route.params.id]
                    }
                ]
            }
        };
    },
    watch: {
        'form.dataConclusao'(newValue) {
            if (!newValue) {
                try {
                    return getClientBase().post(`aplicacao-levantamento/salva-dados-conclusao`, {
                        inventarioRiscoId: this.$route.params.id,
                        dataConclusao: null
                    });
                } catch (error) {
                    showError(this.$toast, error);
                }
            }
            if (newValue < new Date()) {
                try {
                    return getClientBase().post(`aplicacao-levantamento/salva-dados-conclusao`, {
                        inventarioRiscoId: this.$route.params.id,
                        dataConclusao: newValue
                    });
                } catch (error) {
                    showError(this.$toast, error);
                }
            }
        }
    },
    created() {
        this.serviceInventarioRisco = new InventarioRiscoService();
    },
    mounted() {
        this.service = new BaseService('inventario-risco-responsavel');
        this.filtrosExtrasLocal = montarFiltrosExtras(this.filtrosExtras);
        this.load();
    },
    methods: {
        async load() {
            try {
                const retorno = await this.service.findAll({ filtrosExtras: this.filtrosExtrasLocal });
                this.responsaveis = await retorno.data.items.map((el) => el.profissionalSesmt);
                const { data } = await this.serviceInventarioRisco.findByAplicacaoId(this.$route.params.id);
                this.aplicacaoLevantamentoId = data.aplicacaoLevantamentos[0].id;
                this.setDadosRetorno(data);

            } catch (error) {
                showError(this.$toast, error);
            }
        },
        async save() {
            const profissionalSesmtIds = this.responsaveis?.map((p) => p.id);
            const data = {
                inventarioRiscoId: this.$route.params.id,
                etapa: this.etapa,
                profissionalSesmtIds
            };
            try {
                await this.service.save({
                    ...data
                });
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        formatarData(date) {
            return this.$filters.formatDateOnly(date);
        },
        alterFuncaoAcompanhante() {
            this.salvarDadosConclusao();
        },
        alterAcompanhante() {
            this.salvarDadosConclusao();
        },
        alterarObservacao() {
            this.salvarDadosConclusao();
        },
        salvarDadosConclusao() {
            try {
                return getClientBase().post(`aplicacao-levantamento/salva-dados-conclusao`, {
                    inventarioRiscoId: this.$route.params.id,
                    acompanhante: this.form?.acompanhante,
                    funcaoAcompanhante: this.form?.funcaoAcompanhante,
                    observacao: this.form?.observacao
                });
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        onIncluirAnexo(origemId) {
            this.tipoAnexo = TipoAnexoEnum.APLICACAO_LEVANTAMENTO_CONCLUSAO;
            this.origemAnexo = OrigemAnexoEnum.APLICACAO_LEVANTAMENTO_CONCLUSAO;
            this.origemAnexoId = origemId;
            this.showAnexoDialog = true;
        },

        setDadosRetorno(data){
            const aplicacaoLevantamento = data.aplicacaoLevantamentos[0];
            if (aplicacaoLevantamento.dataConclusao) {
                    this.form.dataConclusao = this.formatarData(data.aplicacaoLevantamentos[0].dataConclusao);
            }
            this.form.acompanhante = aplicacaoLevantamento.acompanhante;
            this.form.funcaoAcompanhante = aplicacaoLevantamento.funcaoAcompanhante;
            this.form.observacao = aplicacaoLevantamento.observacao ?? `Tempo de visita\n\nPendências\n\nParticularidades`;
        }
    }
};
</script>